<template>
  <div>
    <h1 :class="$style.pageTitle">ドライブリスト</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-body">
            <div v-if="driveListLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!driveListLoading">

              <a-list
                item-layout="horizontal"
                :data-source="driveList"
                :pagination="true"
              >
                <a-list-item slot="renderItem" slot-scope="item">
                  <a slot="actions"><a-button @click="updateDriveList(item.id, item.name, item.address)">編集</a-button></a>
                  <a-list-item-meta>
                    <div slot="title" class="list-name">
                      <p>{{ item.name }}: {{ item.address }}</p>
                    </div>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>

              <p class="text-center" style="margin-top:20px;">
                <a-button :loading="confirmDriveListLoading" type="primary" @click="onMakeDriveList" :class="$style.filledBtn">
                  追加
                </a-button>
                <router-link v-if="plus" :to="{ name: 'drivingSchedule' }" class="ml-3">
                  <a-button>運行計画表</a-button>
                </router-link>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <a-modal
      :title="driveListTitle"
      :visible="visibleDriveList"
      @cancel="handleCancelDriveList"
    >
      <div>
        <a-form-model
          ref="driveListRef"
          :rules="driveListRules"
          :model="driveListForm"
        >
          <a-form-model-item ref="name" label="名前" prop="name">
            <a-input
              v-model="driveListForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item ref="address" label="住所" prop="address">
            <a-input
              v-model="driveListForm.address"
              @blur="
                () => {
                  $refs.address.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetDriveListId" key="submit" type="danger" :loading="confirmDriveListLoading" @click="handleDeleteDriveList">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelDriveList">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmDriveListLoading" @click="handleOkDriveList" :class="$style.filledBtn">
              {{ driveListOkText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      driveListLoading: true,
      confirmDriveListLoading: false,
      driveList: [],
      driveListTitle: '作成',
      visibleDriveList: false,
      driveListOkText: '作成',
      targetDriveListId: 0,
      driveListForm: {
        name: '',
        address: '',
      },
      driveListRules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
        address: [
          { max: 1023, message: '1023文字までです。', trigger: 'blur' },
        ],
      },
      plus: false,
    }
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'drive_lists')
    result.then(response => {
      this.driveList = response
      this.driveListLoading = false
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ドライブリストの取得に失敗しました。',
        })
        this.driveListLoading = false
      })

    Vue.prototype.$api.send('get', 'user')
      .then(response => {
        this.plus = response.plus_flag === 1
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
      })
  },
  methods: {
    handleCancelDriveList() { this.visibleDriveList = false },
    updateDriveList(id, name, address) {
      this.visibleDriveList = true
      this.targetDriveListId = id
      this.driveListForm.name = name
      this.driveListForm.address = address
      this.driveListTitle = '更新'
      this.driveListOkText = '更新'
    },
    onMakeDriveList() {
      this.visibleDriveList = true
      this.targetDriveListId = 0
      this.driveListForm.name = ''
      this.driveListForm.address = ''
      this.driveListTitle = '作成'
      this.driveListOkText = '作成'
    },
    handleOkDriveList() {
      const _this = this
      this.confirmDriveListLoading = true
      var actionType = 'post'
      var bodyData = {
        name: this.driveListForm.name,
        address: this.driveListForm.address,
      }
      if (this.targetDriveListId !== 0) {
        actionType = 'put'
        bodyData = {
          id: this.targetDriveListId,
          name: this.driveListForm.name,
          address: this.driveListForm.address,
        }
      }

      this.$refs.driveListRef.validate(valid => {
        const sendDriveList = Vue.prototype.$api.send(actionType, 'drive_lists', bodyData)
        sendDriveList.then(response => {
          _this.$notification['success']({
            message: 'ドライブリストを更新しました。',
          })
          if (this.targetDriveListId !== 0) {
            var targetUpdateDriveList = this.driveList.find(e => e.id === this.targetDriveListId)
            targetUpdateDriveList.name = this.driveListForm.name
            targetUpdateDriveList.address = this.driveListForm.address
          } else {
            this.driveList.push(response)
          }
          this.visibleDriveList = false
          this.confirmDriveListLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmDriveListLoading = false
            console.log(error)
          })
      })
    },
    handleDeleteDriveList() {
      const _this = this
      this.confirmDriveListLoading = true
      const send = Vue.prototype.$api.send('delete', 'drive_lists', { id: this.targetDriveListId })
      send.then(response => {
        console.log(response)
        _this.$notification['success']({
          message: 'ドライブリストを削除しました。',
        })
        this.driveList = this.driveList.filter(e => e.id !== _this.targetDriveListId)
        this.visibleDriveList = false
        this.confirmDriveListLoading = false
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.data.data,
          })
          this.visibleDriveList = false
          this.confirmDriveListLoading = false
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.list-name {
  font-size: 1.5rem;
}
</style>
